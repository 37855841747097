import axios from 'axios';
import { GET_MY_BET_API, POST_BET_API, SM_EventMARKET_API } from './url';
import { getAuthHeaders } from './authUtils';

class SMBetServiceClass {

    async getSMEventMarketService() {
        const headers = getAuthHeaders();
        return await axios.get(`${SM_EventMARKET_API}`, {
            headers: headers,
        });
    }

    async postBetAPIService(data) {
        const headers = getAuthHeaders();
        return await axios.post(`${POST_BET_API}`, data, {
            headers: headers,
        });
    }

    async getSMMyBetsService(mId) {
        const headers = getAuthHeaders();
        return await axios.get(`${GET_MY_BET_API}?marketId=${mId}`, {
            headers: headers,
        });
    }

}

const SMBetService = new SMBetServiceClass();
export default SMBetService;
