import { createSlice } from "@reduxjs/toolkit";

const matkabetSlice = createSlice({
    name: 'SMBetSliceData',
    initialState: {
        smEventMarketList: [],
        smMyBetList: []
    },
    reducers: {
        saveSMEventMarket: (state, action) => {
            state.smEventMarketList = action.payload
        },
        saveSMMyBets: (state, action) => {
            state.smMyBetList = action.payload
        },
    }
})

export const { saveSMEventMarket, saveSMMyBets } = matkabetSlice.actions;
export default matkabetSlice.reducer