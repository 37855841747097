import React from "react";
import { Modal, Button } from "react-bootstrap";

const RuleModal = ({ showModal, handleModal }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleModal(false);
        sessionStorage.setItem("ShowRules", false);
      }}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>नियम और शर्त</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        CASINO RULES
        <br /> 1) कोई भी CASINO काम करने से पहले अपने UPLINE से बात कर ले नहीं
        तो भुगतान अपलाइन के हाथ में होगा
        <br /> 2)आगर कोई क्लाइंट क्रेडिट लिमिट (जितने की आईडी है) से जायदा जीत
        जाता है तो जीतने की क्रेडिट लिमिट है अपलाइन एक बार में उतने ही भुगतन का
        जिम्मेवार है
        <br />
        3)भारत से बहार की आईपी आने पर सौदे बहार निकल सकता ह Double player tennis
        ka lenden nhi hoga ipl spacial t&C-कोई भी अगर VPN इस्तेमाल करता है तो
        उसका एकाऊंट ब्लॉक हो जायेगा और जितने पॉइंट का अकॉउंट होगा उसका भुगतान
        करना होगा ,किसी तरह की कोई सुनवाई नहीं होगी
        <br />
        1. लोगिन करने के बाद अपना पासवर्ड बदल लें। 5०००० पॉइंट से ऊपर के किसी भी
        अकॉउंट में 3 गुणा से ज्यादा जीत पर ऊपर का भुगतान नहीं किया जायेगा, इसलिए
        जीत के बाद पॉइंट निकलवा कर काम करे
        <br />
        2.हर सौदा आखरी /हार-जीत का माना जायेगा, किसी भी मैच की ADVANCE लंबी कभी
        भी बंद हो सकता है हर सौदा हार जीत का माना जाएगा
        <br /> 3.एडवांस फैंसी के सौदे अगर कंपनी को लगता है गलत चल गए है तो कंपनी
        बिना किसी रीज़न के मैच से पहले निकाल सकती है
        <br /> 4.ग्राउंड और ग्राउंड कमेंट्री लेकर किये गए सभी सौदे निकल जायेंगे
        निकाल सकती है
        <br /> 5. टेस्ट में पारी डिक्लेअर होने पर जो सेशन रनिंग में हे उस सेशन
        को डिक्लेअर करने के लिए दूसरी टीम के ओवर या बॉल काउंट किये जायेंगे।
        <br /> 6. मैच में ओवर कम होने पर एडवांस सेशन फैंसी कैंसिल हो जाएगी।
        <br /> 7. गेम रद्द या टाई होने पर मैच के सभी सौदे रद्द माने जायेंगे और
        जो सेशन पुरे हो चुके हे, उनके हिसाब से ही Coins कम या ज्यादा होंगे ।
        <br /> 8. एक बार में मैच का सौदा कम से कम 500 (पांच सौ और सेशन का सौदा
        कम से कम 100 ( सौ) तक का ही लिया जायेगा।
        <br /> 9. मैच के दौरान भाव को देख व समझ के ही सौदा करे। किये गए किसी भी
        सौदे को हटाया या बदला नहीं जाएगा। सभी सौदे के लिए स्वयं आप ही जिम्मेदार
        होंगे।
        <br /> 10. मैच या सेशन भाव गलत चलने पर जो भी मैच या सेशन के सौदे हुए हे
        वह स्वतः हट जायेंगे। ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं
        होगा।
        <br /> 11. रेट डिफरेंस सेट करने के लिए कोइन्स के पास वाली इमेज पे क्लिक
        करे और अपना रेट डिफरेंस सेट कर ले।
        <br /> 12. चीटिंग या गलत भाव के सौदे हटा दिए जायेंगे मैच खत्म होने बाद
        भी।
        <br /> 13. अगर आप इस एग्रीमेंट को ऐक्सेप्ट नहीं करते हे तो कोई सौदा नहीं
        कीजिये।
        <br /> 14. मैच अबॉण्डेड और मैच में ओवर कम होने पर जो सेशन, पार्टनरशिप और
        खिलाडी रनिंग में हे या खिलाडी रिटायर हुआ हो । और जो सेशन कम्पलीट हे उनके
        हिसाब से कोइन्स कम या ज्यादा होंगे। और रिजल्ट आने पे जो खिलाडी जहा हे वो
        ही माने जायेंगे।फ़ुटबॉल का कोई भी सौदा अगर 3 मिनट में निकल जाता है तो
        उसकी कोई भी सुनवाई नहीं होगी
        <br /> 15.टेनिस में ज्यादा रोलिंग करने पर जीत की एंट्री डिलीट हो जाएगी
        <br /> 16. कंपनी का डिसीज़न ही फाइनल होगा, उस पर कोई क्लेम मान्य नहीं
        होगा।
        <br /> 17. अगर कोई client एक जगह से ज्यादा user login करके एक जैसी ,या
        cross करता है तो उस account की winning bets निकाल दी जाएंगी।
        <br /> 18. TIED MATCH KA RATE MATCH KE 1 OVER / 10 RUN पहले बंद हो
        जाएगा, हार जीत का सौदा समझ कर सौदा करें।
        <br /> 19. Tennis में advance bets मैच स्टार्ट से पहले निकल सकती है सोच
        कर bet करे
        <br />
<h4>CASINO RULES</h4>1.CASINO ME BETS KARNE SE PEHLE GAMES PAR CLICK KARKE RULES PAD LE 
<br />
2. USER BETS LAGANE SE PEHLE APNE UPLINE SE LENDEN POOCH KAR HI CASINO ME BETS KARE 
<br />
3.AGAR COMPANY KO LAGTA HAI KOI HACKING YA SUSPECTED BETS KARKE USER WIN HUA HAI TOH COMPANY CLINT KA JAMA WALA OR JEETA HUA NEXT DAY 12PM TAK "CHEAT BETS " MARK KARKE POINT "0" KAR SAKTI H 
<br />
4 .AGAR SERVER KI WAJAH SE KOI ERROR AATA HAI TOH BETS CANCEL MANI JAYEGI 
<br />
   COMPANY DECISIONS LAST DECISIONS 
   <br />
   <h4>MATKA RULES</h4>
   
1 . FARIDABAD , GAZIABAD ,GALI, DESAWAR KE RESULT MARKET KE HISAB SE LENDEN HOGA 
<br />
2. CRICKET MATKA -: RESULT 1ST INNING SCORE LAST DIGIT +2ND INNING SCORE LAST DIGIT KO JOD KAR JO NO BANEGA [1ST INN SCORE 75(5) +2ND INNG SCORE 76(6) ]#RESULTNO IS= 56
<br />
3.CRICKET MATKA -: AGAR MATCH KI 1ST INNING KE BAAD 2ND INNING NAHI KHELTE TOH ALL BETS CANCEL HO JAYEGI 
<br />
4. CRICKET MATKA-: 2nd INNG KI BALL DALNE KE BAAD GAME KA RESULT AAJAYEGA MATCH RESULT AAYE YA NA AAYE 
<br />
5.CRICKET MATKA :- AGAR MATCH TIED HOTA HAI TOH COMPANY KO JIS NO PE PROFIT HO RHA HOGA RESULT VALID HOGA [RESULT DECLARED BY SHRIEXCHANGE ]
<br />
      JIS KISI KO RULES ACCEPT HO WAHI MATKA ME BETS KARE BAD ME KOI BEHAS NA KARE KI HUMNE RULES SAHI SE PADE NAHI 
      <br />
      COMPANY DECISIONS LAST DECISIONS 
      </Modal.Body>
      <Modal.Footer style={{ "justify-content": "flex-start" }}>
        <Button
          variant="warning"
          onClick={() => {
            handleModal(false);
            sessionStorage.setItem("ShowRules", false);
          }}
          style={{ fontSize: "13px" }}
        >
          Accept
        </Button>
        <br></br>
        <br></br>
      </Modal.Footer>
    </Modal>
  );
};

export default RuleModal;
