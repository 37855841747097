import { combineReducers } from "redux";
import authSlice from '../slices/authSlice';
import marketSlice from "../slices/marketSlice";
import userSlice from "../slices/userSlice";
import reportSlice from "../slices/reportSlice";
import smBetSlice from "../slices/smBetSlice";

const appReducer = combineReducers({
    authSlice,
    marketSlice,
    userSlice,
    reportSlice,
    smBetSlice
})

export default appReducer