import * as auth from './authActions';
import * as market from './marketActions';
import * as user from './userAction';
import * as report from './reportAction'
import * as SMBet from './SMBetAction'

const actions = {
    ...auth,
    ...market,
    ...user,
    ...report,
    ...SMBet,
    
}

export default actions;