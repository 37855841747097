import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";

const MatkaMarket = () => {
    const navigate = useNavigate();

    const smEventMarketList = useSelector((state) => state.smBetSlice.smEventMarketList.Result);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        actions.getSMEventMarket().finally(() => setLoading(false));
    }, []);

    const handleNavigate = (data) => {
        navigate(`/matkabet/${data.EventName}/${data.EventId}/${data.Mid}`, {
            state: { data },
        });
    };

    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0">
                {/* <div className="d-flex flex-wrap"> */}
                <div className="tag-container">
                    <div className="tag-text">Matka Games</div>
                </div>
                {/* <div className="row g-1"> */}
                <div className="smcardContainer">
                    {loading ? (
                        <div className="loading">Loading...</div>
                    ) : smEventMarketList && smEventMarketList.length > 0 ? (
                        smEventMarketList.map((market) => (
                            <div
                                key={market.id}
                                className="smcard"
                                onClick={() => handleNavigate(market)}
                                onKeyPress={(e) => e.key === "Enter" && handleNavigate(market.id)}
                                role="button"
                                tabIndex={0}
                            >
                                <div className="smimageContainer">
                                    <img
                                        src="/assets/images/numbackgroud.png"
                                        alt={`${market.EventName} - ${market.hindi}`}
                                        className="smimage"
                                        loading="lazy"
                                    />
                                    <div className="smoverlay"></div>
                                </div>
                                <div className="smgameTitle">
                                    <strong>{market.EventName}</strong> {market.hindi}
                                </div>
                                <div className="smgameTime">
                                    {new Date(market.DateModify).toISOString().split('T')[0]} {market.StartTime}  {market.EndTime}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-data text-center">No Data Available</div>
                    )}
                    {/* </div> */}
                </div>
                {/* </div> */}
            </div>

        </NavbarSidebarWrapper>
    );
};

export default MatkaMarket;
