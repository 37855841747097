import { handleUnauthorizedError } from "../../services/authUtils";
import MatkaBetService from "../../services/SMBetService";
import { saveSMEventMarket, saveSMMyBets } from "../slices/smBetSlice";
import store from "../store";

export function getSMEventMarket() {
    return new Promise((resolve, rejact) => {
        MatkaBetService.getSMEventMarketService().then((res) => {
            store.dispatch(saveSMEventMarket(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}

export function postBet(data) {
    return new Promise((resolve, rejact) => {
        MatkaBetService.postBetAPIService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}

export function getSMMyBets(mId) {
    return new Promise((resolve, rejact) => {
        MatkaBetService.getSMMyBetsService(mId).then((res) => {
            store.dispatch(saveSMMyBets(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}