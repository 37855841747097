import React, { useEffect } from "react";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import BetTableList from "./MatkaBetTableList";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";

const MatkaBetList = ({ Mid, BetList }) => {

    const totalAmount = BetList.reduce((sum, item) => sum + parseFloat(item.Amount), 0);

    useEffect(() => {
        actions.getSMMyBets(Mid);
    }, [])

    return (
        <Tab.Container defaultActiveKey="tab1" id="tab-layout">
            <Nav variant="tabs" className="custom-nav-tabs m-0">
                <Nav.Item>
                    <Nav.Link eventKey="tab1" className="custom-nav-link2 cricket">My Bet </Nav.Link>
                </Nav.Item>
                <div className="text-white mt-2 mx-2">
                    Total Bet Amount: {totalAmount}
                </div>
                {/* <Nav.Item>
                    <Nav.Link eventKey="tab2" className="custom-nav-link2">Completed </Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                    <Nav.Link eventKey="tab3" className="custom-nav-link2">Fancy Bets ({filteredFancyList?.length})</Nav.Link>
                </Nav.Item> */}
            </Nav>
            <Row className="justify-content-center">
                <Col>
                    <Tab.Content>
                        <Tab.Pane eventKey="tab1">
                            <div className="tab-content custom-tab-content">
                                <BetTableList />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab2">
                            <div className="tab-content custom-tab-content">
                                <BetTableList />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab3">
                            <div className="tab-content custom-tab-content">
                                <BetTableList />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>

    )
}

export default MatkaBetList;