import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'userListData',
    initialState: {
        userBalanceDetails: {},
        userBalance: {},
        showModal: false,
        betBookShowModal: false
    },
    reducers: {
        saveUserBalance: (state, action) => {
            state.userBalance = action.payload
        },
        saveUserBalanceDetails: (state, action) => {
            state.userBalanceDetails = action.payload
        },
        setCurrentPositionModal: (state, action) => {
            state.showModal = action.payload
        },
        setBetBookShowModal: (state, action) => {
            state.betBookShowModal = action.payload
        },
    }
})

export const { saveUserBalance, saveUserBalanceDetails, setCurrentPositionModal, setBetBookShowModal } = userSlice.actions;
export default userSlice.reducer