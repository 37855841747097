import React, { useEffect, useState } from "react";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import HomeFooter from "../../../components/HomeFooter";
import { useToast } from "../../../components/ToastContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const SignIn = () => {
  const { login } = useAuth(); // Access login function from context

  const { showToast } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
    LatLong: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ip, setIp] = useState(null);
  const news = useSelector((state) => state.marketSlice?.newsData?.Result);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert("Please Allow Location to Access this Application");
          }
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    // Fetch IP Address
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    // Load username from localStorage if "Remember Me" was selected
    const savedUsername = localStorage.getItem("rememberedUsername");
    if (savedUsername) {
      setData((prevData) => ({ ...prevData, username: savedUsername }));
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetchCoordinates(latitude, longitude);
    }
  }, [latitude, longitude]);

  function fetchCoordinates(latitude, longitude) {
    const locationURL = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}`;
    fetch(locationURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!data.username.trim()) {
      showToast("Username is required", "error");
      return;
    }
    if (!data.password.trim()) {
      showToast("Password is required", "error");
      return;
    }
    if (latitude === null || longitude === null) {
      showToast("Geolocation is required to log in. Please enable location services.", "error");
      return;
    }
    setIsLoading(true);
    try {
      const res = await actions.loginAction({
        data: { ...data, LatLong: `${latitude},${longitude}` },
        ip,
      });
      if (!res?.data?.Status) {
        showToast(res?.data?.Result || "Login failed. Please try again.", "error");
      } else if (res.status === 200) {
        login(res.data);
        if (rememberMe) {
          localStorage.setItem("rememberedUsername", data.username);
        } else {
          localStorage.removeItem("rememberedUsername");
        }
        navigate(`/home`);
      }
    } catch (error) {
      const errorMessage = error?.message || "An unexpected error occurred. Please try again.";
      showToast(errorMessage, "error");
    } finally {
      setIsLoading(false);
    }
  };


  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const getHostname = () => {
    try {
      var host = window.location.hostname;
      const dots = host.split(".");
      return dots.at(-2).replace(/\d/g, "");
    } catch (ex) { }
  };

  return (
    <div>
      {/* Header */}
      <header
        className={`pk d-flex flex-column align-items-center position-fixed ${getHostname()} `}
      >
        <div className="container-fluid d-flex align--center py-1">
          <div className="d-flex align-items-center logo_area ps-2 ps-md-3 ps-lg-0">
            <img
              src={`/assets/images/${getHostname()}.png`}
              alt="logo"
              className="w-100"
            />
          </div>
          <div className="right_area d-flex align-items-center order-lg-3 ms-auto ps-2">
            <ul className="ps-0">
              <li className="open">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn loginBtn text-white f-14"
                    onClick={handleLoginClick}
                  >
                    Login
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="marquee_area d-flex align-items-center w-100">
          {" "}
          <span className="ic">
            {" "}
            <img src="./assets/img/speaker_blue.png" alt="" />{" "}
          </span>
          <div className="scrolling-text">{news ? news[0]?.Message : ""}</div>
        </div>
      </header>

      {/* Main Content */}
      <div
        className="main"
        style={{
          backgroundImage: "url('/assets/images/login_bg-compress.jpg')",
        }}
      >
        <div className="container">
          <div className="login">
            <div className="login_form mt-2 p-2">
              <div className="logo_area">
                <img src={`/assets/images/${getHostname()}.png`} alt="" />
              </div>
              <form
                noValidate=""
                className="danger ng-untouched ng-pristine ng-invalid"
              >
                <div className="withEmail">
                  <div className="form-field d-flex align-items-center">
                    {" "}
                    <span className="ic">
                      {" "}
                      <i className="fa-solid fa-user" />{" "}
                    </span>
                    <div className="d-flex w-100">
                      <input
                        type="text"
                        placeholder="Enter Username"
                        value={data.username}
                        name="username"
                        onChange={handleChange}
                        formcontrolname="user_name"
                        className="form-control w-100 m_field ng-untouched ng-pristine ng-invalid"
                      />{" "}
                    </div>
                  </div>
                  <div className="form-field d-flex align-items-center mb-2 position-relative">
                    {" "}
                    <span className="ic">
                      {" "}
                      <i className="fa-solid fa-lock" />
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={data.password}
                      name="password"
                      onChange={handleChange}
                      placeholder="Enter Password"
                      formcontrolname="password"
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                    <button
                      type="button"
                      className="btn eye_ic"
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"
                          } ng-star-inserted icon-black`}
                      />
                    </button>
                  </div>
                </div>

                <div className="form-field d-flex flex-column mb-1 pt-2 mt-3">
                 
                  <label className="d-flex">
                   
                    <input
                      type="checkbox"
                      name="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="me-2"
                    /> 
                    Remember me 
                  </label> 
                </div>
                <div className="form-field d-flex flex-column mb-2 mt-3">
                  <button
                    type="submit"
                    className="btn submit_btn"
                    onClick={handleLogin}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default SignIn;
