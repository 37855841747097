import React, { useEffect } from 'react';
// import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
// import * as MdIcons from 'react-icons/md';
import * as FcIcons from 'react-icons/fc';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { startConnection, stopConnection, subscribeToEvent } from '../../services/SignalRService';


export const SidebarData = () => {
    const marketList = useSelector((state) => state.marketSlice.marketList?.Result);

    useEffect(() => {
        actions.getMarketList();
        startConnection();

        subscribeToEvent('UpdateMarketList', (data) => {
            actions.getMarketList();
        });

        // Don't forget to stop the connection when the component is unmounted
        return () => {
            stopConnection();
        };
    }, [])

    const getSportsSubNav = (sportId) => {

        let filterBySportId = (marketList && Array.isArray(marketList)) ? marketList?.filter((item) => item?.SportsId?.toString() === sportId) : [];

        // Use reduce to group objects by title
        let groupedSubNavArr = filterBySportId?.reduce((accumulator, data) => {

            // Check if the title already exists in accumulator
            const existingGroup = accumulator.find((group) => group.title === data?.SeriesName);

            if (existingGroup) {
                existingGroup.marketNames.push(
                    {
                        title: data?.MarketName,
                        path: `/market/${sportId}/${data?.EventId}`,
                        iconClosed: <IoIcons.IoIosArrowForward />,
                        iconOpened: <IoIcons.IoIosArrowDown />,
                    }
                );
            } else {
                accumulator.push({
                    title: data?.SeriesName,
                    path: '#',
                    iconClosed: <IoIcons.IoIosArrowForward />,
                    iconOpened: <IoIcons.IoIosArrowDown />,
                    marketNames: [{
                        title: data?.MarketName,
                        path: `/market/${sportId}/${data?.EventId}`,
                        icon: '',
                        iconClosed: <IoIcons.IoIosArrowForward />,
                        iconOpened: <IoIcons.IoIosArrowDown />
                    }],
                });
            }

            return accumulator;
        }, []);

        return groupedSubNavArr
    }

    return ([
        {
            title: 'Home',
            path: '/home',
            icon: <FcIcons.FcHome />,
            tag: ''
        },
        {
            title: 'In-play',
            path: '/inplay',
            icon: <img src="/assets/images/in-play.svg" alt="in-play" width="20" height="20" />,
            tag: ''
        },
        {
            title: 'My Market',
            path: '/mymarket',
            icon: <FcIcons.FcShop />,
            tag: ''
        },
    /*  {
            title: 'Matka',
            path: '/matka_market',
            icon: <FcIcons.FcShop />,
            tag: 'Matka Bet',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

            subNav: [
                {
                    title: 'Bet Result',
                    path: '/makta_bethistory',
                    icon: ''
                },
            ]
        },*/
        // {
        //     title: 'Match List',
        //     path: '/matchlist',
        //     icon: <FcIcons.FcRules />,
        //     tag: 'matchs',

        //     iconClosed: <IoIcons.IoIosArrowForward />,
        //     iconOpened: <IoIcons.IoIosArrowDown />,
        // },

        // {
        //     title: 'In-Play',
        //     path: '#',
        //     icon: <img src="/assets/images/in-play.svg" width="20" height="20" />,
        //     tag: '',

        //     iconClosed: <IoIcons.IoIosArrowForward />,
        //     iconOpened: <IoIcons.IoIosArrowDown />,
        // },

        {
            title: 'Cricket',
            path: '/match/4',
            icon: <img src="/assets/images/cricket.png" alt="cricket" width="20" height="20" />,
            tag: 'Sports',
            count: '',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

        },
        {
            title: 'Soccer',
            path: '/match/1',
            icon: <img src="/assets/images/soccer.png" alt="soccer" width="20" height="20" />,
            tag: '',
            count: '',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

            subNav: getSportsSubNav("1")
        },
        {
            title: 'Tennis',
            path: '/match/2',
            icon: <img src="/assets/images/tennis.png" alt="tennis" width="20" height="20" />,
            tag: '',
            count: '',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

            subNav: getSportsSubNav("2")
        },
        {
            title: 'Casino',
            path: '/casinolist',
            icon: <img src="/assets/images/tennis.png" alt="tennis" width="20" height="20" />,
            tag: '',
            count: '',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

            subNav: getSportsSubNav("2")
        },
        
        {
            title: 'Report',
            path: '#',
            icon: <FcIcons.FcRatings />,
            tag: 'Report',

            iconClosed: <IoIcons.IoIosArrowForward />,
            iconOpened: <IoIcons.IoIosArrowDown />,

            subNav: [
                // {
                //     title: 'My Profile',
                //     path: '#',
                //     icon: ''
                // },
                {
                    title: 'Account Statement',
                    path: '/ac_statement',
                    icon: ''
                },
                // {
                //     title: 'Casino Report History',
                //     path: '#',
                //     icon: ''
                // },
                {
                    title: 'Profit & Loss',
                    path: '/profit-loss',
                    icon: ''
                },
                {
                    title: 'Bet History',
                    path: '/bethistory',
                    icon: ''
                },
            ]
        },
        {
            title: 'News',
            path: '/news',
            icon: <FcIcons.FcNews />,
            tag: ''
        },
    ])

}

