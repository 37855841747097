import React, { useState } from "react";
import { useSelector } from "react-redux";
import MyBetDetailModal from "../../../components/Modal/MatkaBet/MyBetDetailModal";
import { formattedDateTime } from "../../../utilities";

const BetTableList = () => {
 
  const myBetList = useSelector((state) => state.smBetSlice.smMyBetList?.Result);

  const [showModal, setShowModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  const handleSetResult = (game) => {
    setSelectedGame(game);
    setShowModal(true);
  };

  const handleActionPress = (betAmount) => {
    console.log(`Result set for ${selectedGame.game}: Bet Amount = ${betAmount}`);
    setShowModal(false);
  };

  return (
    <div className="table-responsive">
      <div className="table-wrapper">
        <div className="table">
          <table className="table">
            <thead>
              <tr>
                <th>Game Name</th>
                <th>Bet No.</th>
                <th>Amount</th>
                <th>Date Time</th>
              </tr>
            </thead>
            <tbody>
              {myBetList?.length > 0 ? (
                myBetList.map((row) => (
                  <tr key={row.id || Math.random()}>
                    <td>{row.MatchName || "N/A"}</td>
                    <td>{row.betNo || "Unknown"}</td>
                    <td>{row.Amount || "N/A"}</td>
                    <td>{formattedDateTime(row.CreatedOn) || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No data available</td>
                </tr>
              )}
            </tbody>

          </table>
        </div>
      </div>

      <MyBetDetailModal
        show={showModal}
        setShow={setShowModal}
        onActionPress={handleActionPress}
      />
    </div>
  );
};

export default BetTableList;
